.App {
    position: relative;
    width: 100%;
    height: 100%;
}

.child-nodes {
    display: flex;
    flex-shrink: 0;
}

.name {
    border: 3px solid;
    border-radius: 15px;
    text-wrap: nowrap;
    padding: 9px 15px;
    flex-shrink: 0;
    margin: 0 60px;
    font-size: 45px;
}

.node {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
}

.first-node {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 10px;
}

.string-vertical {
    height: 300px;
    width: 3px;
    margin-top:-3px;
    background: black;
}

.string-vertical-short {
    height: 30px;
    width: 3px;
    background: black;
}

.string-horizontal {
    width: 100%;
    display: flex;
}

.left-string-horizontal, .right-string-horizontal {
    height: 3px;
    width: 50%;
    flex-shrink: 0;
    background: black;
}

.left-string-horizontal.hide, .right-string-horizontal.hide {
    background: transparent;
    height: 0;
}
